<template>
  <div class="block-header">
    <div class="block-header__inner">
      <IonIcon
        name="logo"
        class="block-header__logo"
      />

      <div
        class="block-header__links"
      >
        <nav class="block-header__nav">
          <ul class="block-header__nav-list">
            <li
              v-for="link in links"
              :key="link._uid"
              ref="listRefs"
              class="block-header__nav-list-item-ref"
              :class="{
                disabled: link.disabled || !defaultRestaurant,
              }"
            >
              <UtilRouteLink
                :link="link.link"
                :anchor="link.anchor"
                class="block-header__nav-list-item"
                :title="link.text"
              >
                <IonIcon
                  :name="link.icon"
                  class="block-header__nav-list-item-icon"
                />
                <div class="block-header__nav-list-item-info">
                  <div class="block-header__nav-list-item-info-subtract upper" />
                  <AtomRichtext :html="link.text" class="block-header__nav-list-item-info-label" />
                  <div class="block-header__nav-list-item-info-subtract lower" />
                </div>
              </UtilRouteLink>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <div
      class="block-header__account"
    >
      <div
        class="block-header__account-logout"
        @click="logout"
        @keydown="logout"
      >
        <span>Logout</span>
        <IonIcon
          name="logout"
          class="block-header__account-logout-icon"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
defineProps({
    links: {
        type: Array,
        default: () => [],
    },
});

const authEndpoints = useAuthEndpoints();
const { session } = authEndpoints.useAuth();
const defaultRestaurant = computed(() => session?.value.restaurantId);

const logout = async () => {
    await authEndpoints.authLogout();
    navigateTo('/login');
};
</script>

<style lang="scss" scoped>
.block-header {
    @include fluid('padding-top', 18px, 82px);
    @include fluid('padding-bottom', 18px, 0px);
    @include z-index('header');

    position: fixed;
    top: 0;
    left: 0;
    width: 215px;
    height: 100%;
    background: var(--c-white);
    box-shadow: 0 0 80px 0 rgba(0, 0, 0, 0.10);
}
.block-header__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.block-header__logo {
    @include fluid('width', 80px, 88px);
    height: auto;
}

.block-header__nav {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.block-header__links {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 79px;
    column-gap: 60px;
}

.block-header__nav-list {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--c-primary);
    font-family: var(--f-family--primary);
    font-weight: var(--f-fw-primary--black);

    @include tablet {
        flex-direction: column;
        align-items: flex-start;
        row-gap: 60px;
    }
}

.block-header__nav-list-item-ref {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 20px 0;

    &.disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}

.block-header__nav-list-item {
    @include fluid('width', 32px, 32px);
    position: relative;
    display: flex;
    transition: color 0.4s ease-in-out;
}

.block-header__nav-list-item-icon {
    @include fluid('width', 32px, 32px);
    min-width: 32px;
    height: auto;
}

.block-header__nav-list-item-info {
    position: absolute;
    top: -14px;
    left: 80px;
    padding: 20px 27px 20px 20px;
    border-radius: 0 50px 50px 0;
    background: var(--c-white);
    box-shadow: 80px 0 80px 0 rgba(0, 0, 0, 0.10);
    opacity: 0;
    pointer-events: none;
    transform: translateX(-20%);
    transition: all 0.3s ease-out;
}

.block-header__nav-list-item-info-subtract {
    position: absolute;
    left: 43px;
    overflow: hidden;
    width: 25px;
    height: 25px;
    background: var(--c-white);
    opacity: 0;

    &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50% 0 0 0;
        content: '';
    }

    &.upper {
        top: -25px;
        transform: rotate(-90deg);
    }

    &.lower {
        bottom: -25px;
    }
}

.block-header__nav-list-item-ref:hover .block-header__nav-list-item-info {
    opacity: 1;
    pointer-events: all;
    transform: translateX(0%);

    .block-header__nav-list-item-info-subtract {
        opacity: 1;

        &:before {
            background: var(--c-grey--lighter);
        }
    }
}

.block-header__account {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 31px 40px;
    background: var(--c-grey--light);
    color: var(--c-primary);
    font-family: var(--f-family--primary);
    font-size: var(--f-size--p);
    font-weight: var(--f-fw-primary--black);
}

.block-header__account-logout {
    display: flex;
    align-items: center;
    column-gap: 50px;
    cursor: pointer
}

.block-header__account-logout-icon {
    @include fluid('width', 32px, 32px);
    height: auto;
}
</style>
