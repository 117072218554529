<template>
  <div class="layout-default">
    <BlockHeader :links="links" />
    <slot />
    <Transition name="slide-down">
      <AppAlertBox v-if="isAlertBoxOpen" />
    </Transition>

    <Transition name="fade-in">
      <AppConfirmPopup v-if="isPopupOpen" />
    </Transition>
  </div>
</template>

<script setup>
const props = defineProps({
    headerLinks: {
        type: Array,
        default: () => [],
    },
});

/*
  Initialize Confirm Popup
*/
const popupStore = useConfirmPopupStore();
const isPopupOpen = computed(() => popupStore.isOpen);

/*
  Initialize Alertbox
*/
const alertBoxStore = useAlertBoxStore();
const isAlertBoxOpen = computed(() => alertBoxStore.isOpen);

const restaurantsStore = useRestaurantsStore();
const { getSelectedRestaurantId } = storeToRefs(restaurantsStore);

const authEndpoints = useAuthEndpoints();
const { session } = authEndpoints.useAuth();

const currentRole = computed(() => {
    if (isArrayNotEmpty(session.value?.restaurants)) {
        return session.value?.restaurants.find(
            (r) => r.id === getSelectedRestaurantId.value,
        )?.userRole;
    }
    return session.value?.userRole;
});

const links = computed(() => {
    if (currentRole.value === 'shift_manager') {
        return props.headerLinks.filter((link) => link.link !== '/intercompany-movements');
    }

    return props.headerLinks;
});
</script>

<style lang="scss">
.layout-default {
    @include grid-layout(12, 40px);
    @include fluid('padding-top', 65px, 65px);
    @include fluid('padding-left', 80px, 80px);
    @include fluid('padding-right', 80px, 80px);

    padding-left: 215px;
}
</style>
